import React, {useState, useEffect} from "react";
import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import authHeader from "./services/auth-header";
import EventBus from "./common/EventBus";
import {useNavigate,Link} from 'react-router-dom'
import axios from "axios";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';

import Chip from '@mui/material/Chip';
import Linkk from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import CircularProgress from '@mui/material/CircularProgress';

import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[50],
    },
    secondary: {
      main: '#f44336',
    },
  },
});


const JurorTests = () => {

  const [currentUser, setCurrentUser] = useState(undefined);
  const [isLoading,setIsLoading] = useState(false);
  ///const [loadingMsg,setLoadingMsg] = useState("Loading Photos");
  const [testCompetitors, setTestCompetitors] = useState([]);
  const [filtTestCompetitors, setFiltTestCompetitors] = useState([]);
  const [testNames,setTestNames]=useState([]);

  const [selectedPhoto,setSelectedPhoto]=useState(null);
  const [selectedMirror,setSelectedMirror]=useState(null);
  const [selectedTestName,setSelectedTestName]=useState(null);
  const [selectedPhotoNumber,setSelectedPhotoNumber]=useState(null);


  const [open, setOpen] = React.useState(false);
  const [filterselect, setFilterSelect] = React.useState("");

const [scrollY,setScrollY]=useState("0");
  //const myHeaders = authHeader();
  const navigate=useNavigate();



  document.title = "Juror Tests Page";

  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    alert("Authentication error. " + err);
    return null;
  }
}

  useEffect(() => {
    //document.body.style.position = "fixed";
    setIsLoading(true);

    //console.log(checkedState);
    let data;
         //const user = AuthService.getCurrentUser();
     const myHeaders = authHeader();
     if (AuthService.getCurrentUser())
     {
       //console.log( AuthService.getCurrentUser());
       setCurrentUser(AuthService.getCurrentUser());
       if (AuthService.getCurrentUser()) {
         const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

         if (decodedJwt.exp * 1000 < Date.now()) {
           EventBus.dispatch("logout");
           navigate("/admin/JurorLogin");
         }
       }
     }
     else {
       EventBus.dispatch("logout");
       navigate("/admin/JurorLogin");
       //setCurrentUser("");
     };

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/jurorTestCompetitors";
    const response = axios.get(API_URL1,{ headers:myHeaders}).then( response=>{
      //console.log(response);      ,{responseType: "blob",  headers:myHeaders})

      return response.data;}).then(data=>
        {
        //console.log(data);
        setTestCompetitors(data);
        //setFiltTestCompetitors(data);
        setTestNames([...new Set(data.map(q => q.testName))]);
        //console.log(testNames);
        setIsLoading(false);

    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/JurorLogin");
        setIsLoading(false);
      //  setCurrentUser(undefined);
    })



  },[]);


  const filter = (e) => {
  const keyword = e.target.value;
 setFilterSelect(e.target.value);
  if (keyword !== 'All Tests') {
    const results = testCompetitors.filter((tcomp) => {
      return tcomp.testName.toLowerCase().startsWith(keyword.toLowerCase());
      // Use the toLowerCase() method to make it case-insensitive
    });
    //console.log(keyword,results);
    setFiltTestCompetitors(results);
  } else {
    setFiltTestCompetitors(testCompetitors);
    // If the text field is empty, show all users
  }
}


  const openPhoto = async (path,mirrorNumber,testName,i) => {
  //let photourl;
  setIsLoading(true);
  let myHeaders = authHeader();
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/file?fileName="+path;
  //console.log(window.scrollY);
  setScrollY(window.scrollY);

  const d= await axios.get(API_URL
      ,{responseType: "blob",  headers:myHeaders})
      .then(response => {
        //console.log(response.data);
        setSelectedPhoto(URL.createObjectURL(response.data));
        setSelectedTestName(testName);
        setSelectedMirror(mirrorNumber);
        setSelectedPhotoNumber(i+1);
        setOpen(true);
        //const photourl=URL.createObjectURL(response.data);
        //photourls.push({mirrorNumber:mirrorNumber,testId:testId,no:i,photo:URL.createObjectURL(response.data)});
      }).catch(err=>{
        EventBus.dispatch("logout");
        //alert("Cannot contact server." +err);
          navigate("/admin/JurorLogin");
        //  setCurrentUser(undefined);
      });
      //console.log(photourls);
  setIsLoading(false);
  };

  const handleClose = () => {
  setOpen(false);
  window.scrollTo(0, scrollY);
};

  return (

  <Box    sx={{

      top:'1%',
      left:'1%',
      right:'1%',
      bottom:'1%',
      display:'block',
      height: '97%',
      width: '97%',


      }}    onContextMenu={(event)=>event.preventDefault()} >
  {open&&!isLoading&&

    <Dialog sx={{
    position:'fixed',
        top:'1%',
        left:'1%',
        right:'1%',
        bottom:'1%',
        display:'block',
        height: '95%',
        width: '95%',


        }}

        fullScreen
      open={open}
      onClose={handleClose}
      onContextMenu={(event)=>event.preventDefault()}

    >
    {/* sx={{position: "absolute",zIndex: 10,overflow:"auto",maxHeight: "100%",}}*/}
    <Box id = "boxphoto" sx={{height: "80%"        , display: 'flex',
              flexDirection: 'column',
              m: 'auto',

}} >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {"Mirror Number: "+selectedMirror+", Photo no: "+selectedPhotoNumber}
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <img key={'photo'}
      id = "photo"
         src={selectedPhoto}
         style={{
           maxHeight: "92%",
     flex: 1,
   }}
       />
       </Box>

    </Dialog>}

     {currentUser && (

       <AppBar theme={theme}
      position="static">
             <Toolbar>
               <IconButton
                 size="small"
                 variant="dense"
                 edge="start"
                 color="inherit"
                 aria-label="menu"
                 sx={{ mr: 2 }}
               >
                 <MenuIcon />
               </IconButton>
               <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
     Juror Test Assessment Page
               </Typography>
               <Button disabled={isLoading} color="inherit">      <Link to={"/admin/JurorLogin"} className="nav-link">
                       {"Logged in as "+currentUser.username}
                     </Link></Button>
             </Toolbar>
           </AppBar>

     )}
{!isLoading&&<Box>
{false&&<FormControl key={"fs"+filterselect} sx={{ m: 1 }} variant="standard" fullWidth>
<label><b>Please select the test to see the competitors: </b></label>

<TextField key={"fst"+filterselect}  select  size="small" disabled={isLoading}
 id="filterselect" name={filterselect}  value={filterselect}
      onChange={filter}>
{!isLoading&& (testNames.length>0)&&testNames.map((item,i) => {

return ( <MenuItem key={"mi_"+i} value={item}>{item}</MenuItem>);
})}
    </TextField></FormControl>}

    <FormControl sx={{ padding: 2}} >
      <FormLabel  key={"fst"+filterselect}  id="demo-controlled-radio-buttons-group"><b>Please select the test to see the competitors:</b></FormLabel>
      <RadioGroup  key={"rdg"+filterselect}
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={filterselect}
        onChange={filter}
      >
      {!isLoading&& (testNames.length>0)&&testNames.map((item,i) => {
      return ( <FormControlLabel key={"mi_"+i} control={<Radio />} value={item}  label={item} />);
      })}
      </RadioGroup>
    </FormControl>

{filtTestCompetitors&&filtTestCompetitors.map(testcomp => {
  return(
<Stack
 display="flex"
  alignItems="center"
 direction="row" spacing={2}>
<Typography variant="h8" component="div">
Mirror Number: {testcomp.mirrorNumber}
</Typography>
 {false&&<Stack direction="row" spacing={2}>
 {testcomp.photoFilePaths.map((path,i)=>{
   return(
     <Typography variant="h5" component="div">
     <Linkk disabled={isLoading}
  id = {`link-${i}`}
  component="button"
  variant="body2"
  underline="hover"
  onClick={(event)=>{openPhoto(path,testcomp.mirrorNumber,testcomp.testName,i);}}
>
  photo{i+1}
</Linkk>
    </Typography>
   )
})
}
</Stack>}
<Stack direction="row" spacing={2}>
{testcomp&&testcomp.thumbnails.map((thumb,i)=>{
  return(
    <Card sx={{  width: {
          sx: 1.0, // 100%
          sm: 150,
          md: 250,
        },border: 0.1,borderColor: 'grey.500'  }} >
        <CardActionArea>
     <CardContent autoFocus onClick={(event)=>{openPhoto(testcomp.photoFilePaths[i],testcomp.mirrorNumber,testcomp.testName,i);}}>
    <Typography variant="h8" component="div">
    Photo No {i+1}
    <CardMedia
      component="img"
      width="200"
      image={`data:image/jpeg;base64,${thumb}`}
      alt="Photo"


    />
   </Typography>
   </CardContent>
   </CardActionArea>
   </Card>

  )
})
}
</Stack>
</Stack>
);
})}

</Box>}
{isLoading &&
<Stack  style={{ alignItems: "center", display: "flex", justifyContent: "center"}}>
              <CircularProgress  style={{ justifyContent: "center", position: "fixed", top: "60%" }} thickness={1} size={150}/>
<span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>Loading...</span>
</Stack>
}

</Box>

);
}

export default JurorTests;
