import {useState,useEffect} from "react";
import React from "react";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import { CircularProgress, Button } from '@material-ui/core';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useParams} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


//import InputLabel from '@mui/material/InputLabel';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';
//import bgLocale from 'date-fns/locale/bg';
//import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
//import CardContent from '@mui/material/CardContent';
//import CardActions from '@mui/material/CardActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import MoreVertIcon from '@mui/icons-material/MoreVert';


import {useNavigate} from 'react-router-dom';
//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    background: "rgb(255, 192, 203)",
    maxLength:"4",
    size:"6"
  }
}));


const CreateRegistration=() => {
  //const {section} = useParams();
  //const section = "Juror/Trainer";
  //console.log(section);
  const initialValues = {president:"",orgname:"",country:"",
      code:"",fname:"",lname:"",dateofbirth:"",email:"",phone: ""
      ,section:"Juror/Trainer" ,idfile:"",diplomafile:"",payLaterCode:""
    };

    // const initialValues = {president:"",orgname:"",country:"Albania",
    //     code:"",fname:"",lname:"",email:"",phone: ""
    //     ,section:section ,idfile:"",diplomafile:""
    //   };
  //Selected Tests. Used for Pricing
  const [selectedTests,setSelectedTests]=useState([]);
  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

  //Boolean indicators of From State
  const [isSubmit,setIssubmit] = useState(false);
//Pay
  const [isPay,setIsPay] = useState(false);

  const [isCountryLoading,setIsCountryLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");

  const [registrationId,setregid]= useState(null);
  const [successUpload,setSuccessUpload] = useState(false);

  const [photofiles, setPhotoFiles] = useState([]);

  //Accepted file types for online submition
  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff'];
  const maxfilesize="10000000";
  const [countries, setCountries] = useState({});
  //const [value, setValue] = React.useState(new Date());

  const [newCategories,setNewcategories]=useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const [payLater, setpayLater] = useState(false);

  const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [selcountrycodes,setSelcountrycodes]=useState([]);

  const navigate=useNavigate();
  const classes = useStyles();


  const checkHandler = () => {
    setIsChecked(!isChecked)

  }
    const checkpayLater = () => {
      setpayLater(!payLater)
    }

const payLaterChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});

}

//TODO: Array length should be = total number of tests. Otherwise, 2 dim, Category-Test// BEST keep testid,checked on list, push/empty/getall
  const [checkedState, setCheckedState] = useState(
    new Array(100).fill(false)
);

const [totalPrice, setTotalPrice] = useState(0);
/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);
  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);


  const handleClickOpen = async () => {
    setIsLoading(true);


    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/organisation";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    const params={code:formValues.code};
    const queryString = new URLSearchParams(params).toString();
    //console.log(queryString);

    let res = await fetch(ORG_API_URL+"?"+queryString).then(async response=>{
    if(!response.ok){//Error on server
          setFormValues({ ...formValues,code:"",president:"",orgname:""});
          alert('Error contacting server.Check your connection and try again later. Error fetching code: '+response);
          //console.log(res);
          setIsLoading(false);
    }
    else { //fetch ok
      //console.log(response);
      let res1 = await response.json()
      .then(data=> {setFormValues({ ...formValues,president:data.president,orgname:data.name,country:data.country});
                          setIsLoading(false);setOpen(true);
                        //  setSelcountrycodes(countries.filter(country=> country.name===data.country).map(filt=>{return filt.iso2Code.toLowerCase()})[0]);
                        })
      //Code does not exist, fetch returns null
      .catch(err=>{//Code is Not Valid
        setFormValues({ ...formValues,code:"",president:"",orgname:""});
        //alert('Error contacting server.Check your connection and try again later. Error fetching code: '+err);
      setIsLoading(false);setOpen(true);});
    }
    })
    .catch (err=>{alert('Error contacting server.Check your connection and try again later.'+err);setIsLoading(false);})

    //process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId
  };

  const handleCloseYes = (value: string) => {
    setOpen(false);
    setCodeAccepted(true);
    setCodeValid(true);
  };
  const handleCloseNo = (value: string) => {
    setOpen(false);
    setCodeAccepted(false);
    setCodeValid(false);
    setFormValues({ ...formValues,code:"",president:"",orgname:""});
  };

  const handleCloseDis = (value: string) => {
    setCodeAccepted(false);
    setCodeValid(true);
    setFormValues({ ...formValues,code:"",president:"",orgname:""});
  };

  useEffect(async () => {
    //console.log(newCategories.length);
    let data;
    setIsCountryLoading(true);

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/countries";
    const response = await axios.get(API_URL1, data).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
        setCountries(data);
    }).catch(err=>{
      //console.log(err);
      alert("Error contacting server.Check your connection and try again later. " + err );
      setIsCountryLoading(false);
      //console.log(err);
    })

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/generalCategories";
    const responsecat = await axios.get(API_URL2, data).then( responsecat=>{
      return responsecat.data;}).then(datacat=>
        {//Use Params SEction is ok
          //console.log(datacat);
          // if (datacat.map(m =>{return m.name}).indexOf(section)===-1){
          //   navigate("/NotFoundPage");
          // }
          // else
          setNewcategories(datacat);

    }).catch(err=>{
        alert("Error contacting server.Check your connection and try again later. " + err);

      }).then(e=>
          {setIsCountryLoading(false);}
      )



      //console.log(isLoading+" "+isCountryLoading);
   },[formErrors]);


const uploadFile3 = async (filetoup,filename,dirname) => {

    let res;
    setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
    const data = new FormData();
    data.append("file", filetoup);
    data.append("filename",filename);
    data.append("dirname",dirname);

    //console.log(formValues.idfile);
    const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
    //console.log(F_URL);
    try{
      res = await fetch(
      F_URL,
        {
          method: 'put',
          body: data,
          // headers: {}
        })
      //console.log(res);
    } catch (err) {
        //console.log(error);
        alert("Error while uploading files. Check your connection and submit your registration again. " + err );
        return false;
      }
      return res.ok;
  }

//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

//Form Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(e.target);
    //console.log("Changed"+e.target.name+":"+e.target.value);

  //  if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email'){
    //Trim leading & trailing white spaces

    //When Change Section make all checked tests false
    if(e.target.name==='section'){
        //console.log(e.target.value);
        checkedState.fill(false);
        //Empty photofiles. Only one category is alllowed
        setPhotoFiles([]);

         /*if (formValues.country==='Ukraine') {
           setTotalPrice(0);
         }
         else
         {*/
         //***Custom Configuration: When specific section is seleceted, test is selected as well
         if (e.target.value ==='Guest')
         {
           //Karfwti timi gia to test me TESTID = 4 pou antistoixei sto Section= Guest
           setTotalPrice(350);
           checkedState.fill(false).fill(true,4,5);
           let tests=[];
           tests.push(4);
           setSelectedTests(tests);
         }
         //***Set price to 0 when section is changed
           else setTotalPrice(0);
    }
    if (e.target.name ==='idfile'|| e.target.name ==='diplomafile')
     {//TODO: SETFORMERRORS
       //console.log(e.target.files[0]);
      // if (e.target.files[0]){
            //console.log(e.target);
            let selected=e.target.files[0];

           //Everything is OK
             if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
             {
               setFormValues({ ...formValues,[name]: selected});

             }//File too large
            else if (selected && selected.size>maxfilesize) {
               e.target.value='';
               setFormValues({ ...formValues,[name]: ''});
               //setFile('');
               alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
               //return {<Alert severity="error">Accepted file types: {types} Max file size: 5MB </Alert>}
             }//Wrong file type
            else if (selected&&!types.includes(selected.type))
            {
              e.target.value='';
              setFormValues({ ...formValues,[name]: ''});
              alert('Accepted file types:'+ types);
            }
    //  }

       }
      else {
        //console.log("Changed"+e.target.name+":"+e.target.value);
        //console.log("Trimmed"+e.target.name+":"+e.target.value.trim());
        //if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email')
        //setFormValues({ ...formValues,[name]: value.trim()});
        //else
        setFormValues({ ...formValues,[name]: value});
       }

    };

    //FileToUpload Changes: Only for online sections
    const handleChangeFile=(id,section,number_photos)=>(e) =>{

        let selected = e.target.files[0];
        let filename=e.target.name;


        //Everything is OK
          if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
          {

            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, frontfile: e.target.files[0] }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile: e.target.files[0] }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile3: e.target.files[0] }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                     ph.id === id
                         ? { ...ph, sidefile4: e.target.files[0] }
                         : { ...ph }
                 )
               );


          }//File too large
         else if ( selected&& selected.size>maxfilesize) {
            e.target.value='';
            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, frontfile: '' }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, sidefile: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    ph.id === id
                        ? { ...ph, sidefile3: '' }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     ph.id === id
                         ? { ...ph, sidefile4: '' }
                         : { ...ph }
                 )
               );


            alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
          }//Wrong file type
           else if (selected&&!types.includes(selected.type)){
           e.target.value='';
           if (e.target.name==='frontfile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, frontfile: '' }
                      : { ...ph }
              )
            );

           else if (e.target.name==='sidefile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, sidefile: '' }
                      : { ...ph }
              )
            );
            else if (e.target.name==='sidefile3')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile3: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile4')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile4: '' }
                        : { ...ph }
                )
              );
          // setFormValues({ ...formValues,[name]: ''});
           alert('Accepted file types:'+ types);
         }

        //setPhotoFiles(list1);
    };

  const handleCheckTest=(e,secname,position,mustUpload)=>{

    const  updatedCheckedState = checkedState.map((item, index) =>
    index === position ? !item : item);

    setCheckedState(updatedCheckedState);

   //console.log(updatedCheckedState);
   //console.log(checkedState);

   let newList=[];
   if (e.target.checked && mustUpload){
     //append to list
  //  console.log("Checked");
    newList = photofiles.concat({'sectionname':secname,'id': position,
    testName:newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replace(/,/g,"")
    ,photonumber:mustUpload,'frontfile':'','sidefile':'','sidefile3':'','sidefile4':''});
      setPhotoFiles(newList);
    //console.log(newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replaceAll(",",""));


  } else if (mustUpload){
     //remove from list
     const newList = photofiles.filter((item) => item.id !== position);
     //console.log(photofiles.filter((item) => item.id !== position));
    setPhotoFiles(newList);
  }


  //Selected Tests
  let tests=[];
  for (let i = 0; i < updatedCheckedState.length; i++) {
      if (updatedCheckedState[i] === true) {
          tests.push(i);
      }
  }
  setSelectedTests(tests);
  let tr =
  newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
  .reduce((sum,test)=>{ if (tests.includes(test.testId)) {sum=test.registrationFee} return sum},0));

   /*if (formValues.country==='Ukraine') {
     setTotalPrice(0);
   }
   else
   {*/
     setTotalPrice(tr[0]);/*console.log(tr[0]);*/}
   //};
   /*console.log(selectedTests);

   console.log(newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
   .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replaceAll(","," ").replace(/\s+/g, ' '));
*/
   //console.log(selectedTests.map(test=>{if test.testId})

  const validateSubmit = async (e) => {

    e.preventDefault();
    //setIssubmit(true);
    //console.log(formValues);
    setLoadingmessage("Validating Registration...Please Wait...");
    setIsLoading(true);//console.log("LOAD true");

    if(validate(formValues,photofiles)) {
      //console.log(formValues);
      let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');
      let validationError=[];
      const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/competitorValidation";

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'plain/text');

      //console.log(formValues);
      //console.log(formatedDateofBirth);
      const params={code:formValues.code, name:formValues.fname,lastName:formValues.lname,country:formValues.country,email:formValues.email,dateOfBirth:formatedDateofBirth,testId:selectedTests};
      const queryString = new URLSearchParams(params).toString();
      //console.log(queryString);

      fetch(COMP_API_URL+"?"+queryString)
        .then(response => response.json())
        .then(data =>
          {if(data.validCompetitorRegistration) {setValidated(true);/*console.log("True");*/ handleSubmit();}
            else {
              validationError.push({name:"Validation Error",value:  data.errorMessage});
              setFormErrors(validationError);
              setIsLoading(false);//console.log("LOAD false");
            };
      })
        .catch(err=>{ setIsLoading(false);alert("Error contacting server. Check your connection and try again later. " + err );})

    }
    else { setIsLoading(false);}

  }

const handlePay = (e)=>{
//setIsLoading(true);
if(!isPay){
//  setIsLoading(true);
  setIsPay(true);}
else {e.preventDefault();}
}

  const handleSubmit = async(e) => {
    setLoadingmessage("Commiting Registration...Please wait...");
    let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');
    let upload_number_per_section=0;

    //setIsLoading(true);
    if(!isSubmit) { //Submit only once!
    setIssubmit(true);
  //console.log("Try Submit");

    const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/registrationwrite";

   const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'text/plain');

  /*  console.log(  JSON.stringify({president:formValues.president,
        orgname:formValues.orgname,fname: formValues.fname,lname: formValues.lname, country:formValues.country,code:formValues.code,email:formValues.email,
         phone:formValues.phone,section:formValues.section,totalprice:totalPrice,idfile:formValues.idfile,testId:selectedTests
      }));*/

    const options = {
      method: 'POST',
      body:
      JSON.stringify({
        president:formValues.president,orgname:formValues.orgname,fname: formValues.fname,
        lname: formValues.lname,country:formValues.country,code:formValues.code,
        email:formValues.email,phone:formValues.phone,section:formValues.section,totalPrice:totalPrice,testIds:selectedTests,pendingPayment:payLater,
        dateOfBirth:formatedDateofBirth
      }),
      myHeaders
    };

    let res = await fetch(API_URL,options)
    .then( response => {    if(!response.ok) throw new Error(response.status);
       else return response.json();})

    .then( async d=>{

      //console.log(d);
      setregid(d);
        //console.log(formValues.idfile.name.split('.').pop());
        //Upload diploma + id compulsory files.
        let up1 = await uploadFile3(formValues.idfile,"idfile"+"."+formValues.idfile.name.split('.').pop(),d);
        let up2 = await uploadFile3(formValues.diplomafile,"diplomafile"+"."+formValues.diplomafile.name.split('.').pop(),d);
          //console.log(up1,up2);
        //  if (up1 && up2) setIsLoading(false);
        let u1=true;
        let u2=true;
        let u3=true;
        let u4=true;

          if (photofiles.length >0){//There are some files to upload
            upload_number_per_section=photofiles[0].photonumber;
            for(let i=0; i<photofiles.length;i++){
              u1 = u1&& await uploadFile3(photofiles[i].frontfile,"photo1"+"."+photofiles[i].frontfile.name.split('.').pop(),d+"/"+"Test"+photofiles[i].id);
              //console.log(i+"Submited u1="+u1);
              if (photofiles[i].sidefile)
                {u2 = u2 && await uploadFile3(photofiles[i].sidefile,"photo2"+"."+photofiles[i].sidefile.name.split('.').pop(),d+"/"+"Test"+photofiles[i].id);
                //console.log(i+"Submited u2="+u2);
              }
              if (photofiles[i].sidefile3)
                {u3 = u3 && await uploadFile3(photofiles[i].sidefile3,"photo3"+"."+photofiles[i].sidefile.name.split('.').pop(),d+"/"+"Test"+photofiles[i].id);
              //console.log(i+"Submited u2="+u2);
                }
              if (photofiles[i].sidefile4)
                {u4 = u4 && await uploadFile3(photofiles[i].sidefile4,"photo4"+"."+photofiles[i].sidefile.name.split('.').pop(),d+"/"+"Test"+photofiles[i].id);
                //console.log(i+"Submited u2="+u2);
                }

          }
        }

          //DONE: include u1,u2 in case upload photofiles.length >0
          //console.log(up1,up2,u1,u2,u3,u4,photofiles.length);
          //console.log(up1,up2,u1,u2,photofiles.length,((up1 && up2) &&(photofiles.length===0||(photofiles.length ===1 &&u1)||(photofiles.length ===2 &&(u1&&u2))) ) );
          //console.log(up1,up2,u1,u2,upload_number_per_section,photofiles.length,((up1 && up2) &&(upload_number_per_section===0||(upload_number_per_section ===1 &&u1)||(upload_number_per_section ===2 &&(u1&&u2))) ) );
        //  if ((up1 && up2) &&(photofiles.length===0||(upload_number_per_section ===1 &&u1)||(upload_number_per_section ===2 &&(u1&&u2))) ) { //Upload successfull

            if ((up1 && up2) &&(photofiles.length===0||(upload_number_per_section >=1 &&u1&&u2&&u3&&u4)) ) { //Upload successfull

            setLoadingmessage("Commiting Registration...Please wait...");
            const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/uploadedPhotos";

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'plain/text');

            const params={registrationId:d,successfulUpload:up1};
            const queryString = new URLSearchParams(params).toString();
            //console.log(queryString);

            let res2 = await fetch(COMP_API_URL+"?"+queryString,     {
                   method: 'post',
                 })
              .then(response => response.json())
              .then(data =>
                {//Upload Success
                  setSuccessUpload(true);
                  setIsLoading(false);
                })
              .catch(err=>{
                setIsLoading(false);
                alert("Error while uploading files. Check your connection and submit your registration again. " + err );
              })
          }//Upload failed
          else {
            //console.log("Upload FAILED");
            setIsLoading(false);
          alert ("Error while uploading files. Check your connection and submit your registration again. Error uploading files: "+ up1 + up2 +u1 +u2 + u3 + u4 );setIsLoading(false);}
    })
    .catch(err=>{
      setIsLoading(false);
      alert("Error commiting registration. Check your connection and submit your registration again. " + err);
    })

   }
   else {e.preventDefault(); setIsLoading(false);}
 }


  const validate=(values,photos) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    //const phoneregex =^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$;

    const errors=[];
    //console.log(moment().diff(moment(formValues.dateofbirth), "years")>100);

    values.email=values.email.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.lname=values.lname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.fname=values.fname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');


      if(!values.email){
              errors.push({name:'email',value:"Email is required!"});
              //push({id: 4, name: "Mehul"})
        }
      if (!regex.test(values.email)){
              errors.push({name:'email',value: "Not a valid email format!"});
          }
      if (!values.president)
            {errors.push({name:'president',value:"OMC Member Country President field is required!"});}
      if (!values.orgname)
            {errors.push({name:"orgname",value:"Member country Organization name is required!"});}
      if (!values.code || values.code.length>10)
        {errors.push({name:"code",value:"Country Organization Code is required!"});}
      if (!values.fname || values.fname.length>100)
        {errors.push({name:"fname",value:"First Name is Required!"});}
      if (!values.lname || values.lname.length>100)
        {errors.push({name:"lname",value:"Last Name is Required!"});}
      if (values.phone.length<8 || values.phone.length>20)
        {errors.push({name:"phone",value: "Enter a valid phone!"});}
      if (values.idfile.length===0)
          {errors.push({name:"idfile",value: "Enter an id File!"});}
      if (values.diplomafile.length===0)
          {errors.push({name:"diplomafile",value:  "Enter a Photo for Diploma!"});}
      if (!values.dateofbirth||values.dateofbirth=== undefined||!moment(formValues.dateofbirth).isValid()||moment().diff(moment(formValues.dateofbirth), "years")>110)
          {errors.push({name:"dateofbirth",value:  "Enter a valid date of birth!"});}
      if (photos && photos.length) //user must upload 1 or 2 photos for every selected test
        {
            //console.log(photos);
            photos.map((test) => {

              if((test.frontfile==='' && test.photonumber>=1 )|| (test.sidefile==='' && test.photonumber>=2) || (test.sidefile3==='' && test.photonumber>=3)|| (test.sidefile4==='' && test.photonumber>=4))

                //errors.push({name:"photoerror",value:"Please enter "+ test.photonumber +" photos for online section "+formValues.section});
                errors.push({name:"photoerror",value:"Please enter "+ test.photonumber +" photos for online section "+formValues.section+" test "+test.testName});
              })

    //    newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
    //                      .map((tr,i)=>{ if (tr.testId===)  {return tr.testName} else return " "})).toString().replaceAll(","," ").replace(/\s+/g, ' ');

        }
      if (payLater&&values.payLaterCode!==process.env.REACT_APP_PENDING_PAYMENT_CODE)
            {errors.push({name:"payLaterCode",value:  "Not a valid code for later payment"});}
            //console.log(process.env.REACT_APP_PENDING_PAYMENT_CODE+" "+values.payLaterCode);

      /*console.log(errors);*/
      setFormErrors(errors);

      if(errors.length===0)    return true;
      else return false;
  }

  return(

      <div className="create">

      <h1>Registration Form - Academy</h1>
      <Alert severity="info" variant="outlined"><b>Trainers/Jurors Global Certification fess $500. Includes - Lunches & Sunday Gala Night. Guest Participation $350 - Lunch and Sunday Gala Dinner</b></Alert>

        <Alert severity="warning" variant="outlined"><b>Registration will be rejected if the information below is not complete.</b></Alert>
        {!formValues.code&&<Alert severity="info" variant="outlined">
        <b>Please enter your organization code before filling in the form.<br/>Contact your organization president to provide you with the corresponding organization code.</b></Alert>}


        <form action = {process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId} method="POST">
        <div className="code">
        <Stack direction="row" justifyContent="left" alignItems="left" spacing={4}>
        <label>Enter your code</label>
        <TextField disabled={validated||isLoading}
        type="text" required
        style ={{width: '50%'}}
        name= "code"
        value={formValues.code}
        onChange={codeChange}
        onKeyPress={handleKeypress}

        />
        <Button disabled={validated||isLoading} id="codebutton" color="primary" variant="contained" onClick={handleClickOpen}  autoFocus>
        Enter Code
        </Button>
        </Stack>
      </div>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {formValues.orgname===""&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"":"Not a  valid code"}
      </DialogTitle>
        <Alert severity="error">{codeValid?"Please ask your president for the right information and enter proper code.":"Please enter a valid code!"}</Alert>
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
        { formValues.orgname!==""&&<div>
        <DialogTitle id="alert-dialog-title">
          {"Accept organization, president for code: "+formValues.code+" ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {"Organization name: "+formValues.orgname}<br />
          {"President name: " + formValues.president}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDis}>Disagree</Button>
          <Button onClick={handleCloseYes} autoFocus>
            Agree
          </Button>
        </DialogActions></div>
      }
      </Dialog>

        <fieldset disabled={!codeAccepted||validated||isLoading}>
        <div className="reqfields">
          <label>OMC Member Country President</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="president"
            value={formValues.president}
            onChange={handleChange}
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
          />
          <label>Member country organization name</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="orgname"
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
            value={formValues.orgname}
            onChange={handleChange}
              />

            {countries.length&&
            <FormControl fullWidth >
            <label>Country</label>
            <TextField
              disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            InputProps={{
                   readOnly: true,
                 }}
            name="country"
            value={formValues.country}
            //onChange={handleChange}
            >
            {countries.map(item => {
            // return (<option key={item.id}>{item.name}</option>);
           })}
            </TextField>
            </FormControl>}

            <label>Attendee First Name</label>
            <TextField type="text" required
            error={formErrors.some(error => error.name === "fname")}
            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name = "fname" value={formValues.fname}
            onChange={handleChange}/>

            <label>Attendee Last Name</label>
            <TextField type="text" required
              error={formErrors.some(error => error.name === "lname")}
            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name = "lname" value={formValues.lname}
            onChange={handleChange}
            />
            <label>Attendee Birth Date</label>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
           disableFuture
          //  minDate={new Date("1920-31-12")}
          name="dateofbirth"
           views={['year', 'month', 'day']}
           openTo="year"
           //format="YYYY-MM-DD"
           disabled={!codeAccepted||validated||isLoading}
           //minDate={new Date("1920-31-12")}
           error={formErrors.some(error => error.name === "dateofbirth")}
           value={formValues.dateofbirth}
           onChange={(newValue) => {
            setFormValues({ ...formValues,['dateofbirth']: newValue});
           }}

           renderInput={(params) => <TextField style ={{width: '100%'}}  {...params} />}
   />

        </LocalizationProvider>

            <label>Attendee Email</label>
            <TextField type="email" required
            error={formErrors.some(error => error.name === "email")}

            disabled={!codeAccepted||validated||isLoading}
            style ={{width: '100%'}}
            name= "email" value={formValues.email}
            onChange={handleChange}
            />
            {/*<label>Competitor phone</label>
            <TextField type ='text' required name='phone'
              disabled={!codeAccepted||validated||isLoading}
            error={formErrors.some(error => error.name === "phone")}
            style ={{width: '100%'}}
             value={formValues.phone}
            onChange={handleChange}
            />*/}
            <label>Attendee Phone</label>
            <PhoneInput required name="phone" key="phone"   countryCodeEditable variant="outlined"
            color="secondary"
              disabled={!codeAccepted||validated||isLoading}
              //error={formErrors.some(error => error.name === "phone")}
              //autoFormat="false"
              name="phone"
            //default= {selcountrycodes}
            //style ={{width: '100%'}}
            value={formValues.phone}
            //default={countries.filter(country=> country.name===formValues.country).map(filt=>{return filt.iso2Code.toLowerCase())}
            onChange={(newValue) => {
             setFormValues({ ...formValues,['phone']: newValue});
        //    console.log(newValue);
        //    console.log(countries);
        //    console.log(selcountrycodes);
        //    console.log(countries.filter(country=> country.name===formValues.country).map(filt=>filt.iso2Code.toLowerCase()));

            }}
            />

              </div>

              <div>
            {/*}<label> Competitor ID Card or Driver Lisence or Passport     </label>
              <input key="idfile" type="file" onChange={handleChange}  id="idfile"  name = "idfile" className="idfile" accept={types} />*/}
              <input style={{ display: 'none' }}  key="idfile" type="file" onChange={handleChange} id="idfile"  name = "idfile" accept={types}/>

              <label htmlFor="idfile">Attendee ID Card or Driver Lisence or Passport<span className="reqfiles">*</span><br/>
                 <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                   {formValues.idfile?"Change Selection":"Upload file"}
                 </Button>
                 {formValues.idfile&&
                 <Card sx={{ maxWidth: 350 }}>
                 <CardMedia
                   component="img"
                   height="194"
                   image={URL.createObjectURL(formValues.idfile)}
                   alt="Identity Photo"
                 />
               </Card>}
                </label>


            {/*<label> Competitor Photo for Diploma    </label>
              <input key="diplomafile" type="file" onChange={handleChange}  id="diplomafile"  name = "diplomafile" className="diplomafile" accept={types} />*/}
              <input style={{ display: 'none' }}  key="diplomafile" type="file" onChange={handleChange} id="diplomafile"  name = "diplomafile" accept={types}/>
              <label htmlFor="diplomafile">Attendee Photo for Diploma<span className="reqfiles">*</span> <br/>

                 <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                 {formValues.diplomafile?"Change Selection":"Upload file"}
               </Button>
              {formValues.diplomafile&&
              <Card sx={{ maxWidth: 350 }}>
              <CardMedia
                component="img"
                height="194"
                image={URL.createObjectURL(formValues.diplomafile)}
                alt="Diploma Photo"
              />
            </Card>}
              </label>

              </div>
              {!isCountryLoading&& (newCategories.length>0)&&formValues.section!==""&&<Box>

              <FormControl key={"fc_"+formValues.section} sx={{ m: 1 }} variant="standard" fullWidth>
              <FormLabel>Attendance type</FormLabel>

              <RadioGroup row   key={"tf_"+formValues.section}  size="small" disabled={!codeAccepted||validated||isLoading||isCountryLoading}
               id="section" name="section"  value={formValues.section}
                    onChange={handleChange}>

              {newCategories.map((item,i) => {
              //console.log(item,formValues.section,newCategories.length);
              return (<FormControlLabel key={"mi_"+i} control={<Radio />}  value={item.name}
              label={item.name /*Take price from first test of section*/
                +" "+item.testResponses[0].registrationFee+" "  + "$"} />); //map(filt=>{return filt.frontfile})[0]
              })}
              </RadioGroup></FormControl>
              </Box>}
            {
              !isCountryLoading&&(newCategories.length>0)&&formValues.section!=="" && newCategories.filter(section=> section.name===formValues.section).map((section,j) => (
              <div key={j} className = "uploadphotos" >
                <ul  key={j}  className="testlist">
                  { section.testResponses.map((test,i) => (

                  <li key={i} className="unordered-list">
                    <div  key={i} className="list-item">
                      <label  key={i}   htmlFor={`custom-checkbox-${i}`}>
                        <Checkbox color="default"
                          id={`custom-checkbox-${i}`}
                          label={test.testName}
                          value={test.testId}
                          checked={checkedState[test.testId]}
                          disabled = {section.name==='Guest'?true:false}

                          onChange={(event) => handleCheckTest(event,section.name ,test.testId,test.uploadRegistrationPhotos)}
                        />{test.testName/*+" ("+test.registrationFee+"$)"*/}  </label>

                        {/*Photo1*/
                          checkedState[test.testId]&& (test.uploadRegistrationPhotos>0)&&

                        <Stack  key={'div1_${i}'} spacing={1}>
                        <input  style={{ display: 'none' }} type="file" key={`frontfile_${i}`} name = "frontfile"
                        onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                        id={`frontfile_${i}`}  accept={types} required/>
                          <label htmlFor={`frontfile_${i}`}>
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''&&  <Alert variant="outlined" severity="info">Please upload photo</Alert>}
                        {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                            <Card sx={{ maxWidth: ((
                            photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Nails Fantasy M.Hand"
                          )
                            ?500:250)}}>
                          {/*Card header for nails not painting box test*/
                            photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''
                          &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                          &&
                          <CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                          }}
                            title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                            //title={"Participation photo 1"}
                             //subheader="September 14, 2016"
                           />}
                           {/*Card header painting box test*/
                             photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''
                           &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Nail Painting-Box"
                           &&<CardHeader titleTypographyProps={{fontSize: 18,}}
                             title={"Suggested photo for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                             //title={"Participation photo 1"}
                              //subheader="September 14, 2016"
                            />}
                           {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''&&<CardHeader
                           titleTypographyProps={{
                             fontSize: 18,
                           }}
                             //title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                             title={"Participation photo 1"}
                              //subheader="September 14, 2016"
                            />}
                          {/*Card Photo1 for Tests except from Nail Painting-Box/ Eyebrow Shaping & Tinting/Nails Salon Manicure Design*/
                            photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                          &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrow Shaping & Tinting"
                          &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nails Salon Manicure Design"
                          &&<CardMedia
                            component="img"
                            height= "300"
                            src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                            URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/Photo1.jpg"}
                                                     //image='front.jpg'
                           alt="Photo1"
                          />}
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Nail Painting-Box"&&<CardMedia
                            component="img"
                            height= "300"
                            src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                            URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/BoxNails.jpg"}

                            styles = {
                                {height: 0,paddingTop: '56.25%', marginTop:'30'}}
                                                     //image='front.jpg'
                           alt="Photo1"
                        />}
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Eyebrow Shaping & Tinting"&&<CardMedia
                          component="img"
                          height= "300"
                          src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                          URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/Eyebrows Shaping and Tinting1.PNG"}

                          styles = {
                              {height: 0,paddingTop: '56.25%', marginTop:'30'}}
                                                   //image='front.jpg'
                         alt="Photo1"
                        />}
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Nails Salon Manicure Design"&&<CardMedia
                          component="img"
                          height= "300"

                          src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                          URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/Nails Salon Manicure Design.jpg"}

                               alt="Photo1"
                        />}


                        </Card>
                        <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]?"Change Selection":"Upload file"}
                        </Button>
                        </label>
                            {/*<img src="front.jpg"  id="front"/>*/}
                            {/*<input  type="file" key="photo1" onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                              id="frontfile" name = "frontfile" accept={types} required />*/}
                            </Stack>
                          }

                          {/*Photo2*/
                            checkedState[test.testId]&& (test.uploadRegistrationPhotos>1)&&
                            <Stack  key={'div2_${i}'} spacing={1}>
                            <input  style={{ display: 'none' }} type="file" key={`sidefile_${i}`} name = "sidefile"
                            onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                            id={`sidefile_${i}`}  accept={types} required/>
                              <label htmlFor={`sidefile_${i}`}>
                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]===''&&  <Alert variant="outlined" severity="info">Please upload photo</Alert>}
                            {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                            <Card sx={{ maxWidth: (formValues.section==="Senior Ladies Aesthetics"?250:250)}}
                            >
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]===''&&<CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                          }}

                              title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                           />}
                           {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]!==''&&<CardHeader
                           titleTypographyProps={{
                             fontSize: 18,
                           }}

                          //     title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                             title={"Participation photo 2"}
                              //subheader="September 14, 2016"
                            />}

                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrow Shaping & Tinting"&&<CardMedia
                              component="img"
                              height="300"
                              image={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]!==''?
                              URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]):"/"+`${formValues.section}`+"/Photo2.jpg"}
                              alt="Photo2"
                            />}
                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Eyebrow Shaping & Tinting"&&<CardMedia
                              component="img"
                              height="300"
                              image={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]!==''?
                              URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]):"/"+`${formValues.section}`+"/Eyebrows Shaping and Tinting2.PNG"}
                              alt="Photo2"
                            />}
                          </Card>
                               <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                               {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile})[0]?"Change Selection":"Upload file"}
                             </Button>
                            </label>
                            </Stack>
                          }
                          {/*Photo3*/
                            checkedState[test.testId]&& (test.uploadRegistrationPhotos>2)&&
                            <Stack  key={'div3_${i}'} spacing={1}>
                            <input  style={{ display: 'none' }} type="file" key={`sidefile3_${i}`} name = "sidefile3"
                            onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                            id={`sidefile3_${i}`}  accept={types} required/>
                              <label htmlFor={`sidefile3_${i}`}>
                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]===''&&  <Alert variant="outlined" severity="info">Please upload photo</Alert>}
                            {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                            <Card sx={{ maxWidth: (formValues.section==="Senior Ladies Aesthetics"?250:250)}}
                            >
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]===''&&<CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                          }}

                              title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                           />}
                           {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]!==''&&<CardHeader
                           titleTypographyProps={{
                             fontSize: 18,
                           }}

                          //     title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                             title={"Participation photo 3"}
                              //subheader="September 14, 2016"
                            />}

                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrow Shaping & Tinting"&&<CardMedia
                              component="img"
                              height="300"
                              image={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]!==''?
                              URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]):"/"+`${formValues.section}`+"/Photo3.jpg"}
                              alt="Photo3"
                            />}
                          </Card>
                               <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                               {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile3})[0]?"Change Selection":"Upload file"}
                             </Button>
                            </label>
                            </Stack>
                          }
                          {/*Photo4*/
                            checkedState[test.testId]&& (test.uploadRegistrationPhotos>3)&&
                            <Stack  key={'div4_${i}'} spacing={1}>
                            <input  style={{ display: 'none' }} type="file" key={`sidefile4_${i}`} name = "sidefile4"
                            onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                            id={`sidefile4_${i}`}  accept={types} required/>
                              <label htmlFor={`sidefile4_${i}`}>
                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]===''&&  <Alert variant="outlined" severity="info">Please upload photo</Alert>}
                            {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                            <Card sx={{ maxWidth: (formValues.section==="Senior Ladies Aesthetics"?250:250)}}
                            >
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]===''&&<CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                          }}

                              title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                           />}
                           {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]!==''&&<CardHeader
                           titleTypographyProps={{
                             fontSize: 18,
                           }}

                          //     title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                             title={"Participation photo 4"}
                              //subheader="September 14, 2016"
                            />}
                            {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrow Shaping & Tinting"&&<CardMedia
                              component="img"
                              height="300"
                              image={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]!==''?
                              URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]):"/"+`${formValues.section}`+"/Photo4.jpg"}
                              alt="Photo4"
                            />}
                          </Card>
                               <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading} component="span">
                               {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.sidefile4})[0]?"Change Selection":"Upload file"}
                             </Button>
                            </label>
                            </Stack>
                          }

                          {/*  <label key="back"> Back photo     </label>
                            <img src="back.jpg" className ="photouploadpreview"  alt="IMAGE" id="back"/>
                          <input type="file" onChange={handleChangeFile(test.testId,section.name)}
                          id="backfile" name = "backfile" className="inputfile" accept={types} required/>*/}

                            { /*file && <div>{ file.name }</div> */}

                    </div>
                  </li>

                ))
              }
                </ul>
                </div>
              ))
            }

            <Stack direction="column" className="agree">
            <label>
            <Checkbox color="default"
                      id="agree"
                      checked={isChecked}
                      onChange={checkHandler}/>
            I, the undesigned, have read and approve this discharge and release </label>
            <a href="https://omchairworld.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms&Conditions</a>
            </Stack>

</fieldset>
          {/*<Alert severity="warning" variant="outlined"><b>Do not enter organisation code here. Ask your president for pending payment code which allows you to pay later.</b></Alert>*/}
          <Stack direction="row" spacing={6}>
          <span>
          <Checkbox color="primary"
                    id="paylater"
                    checked={payLater}

                    onChange={checkpayLater}
                    disabled = {!codeAccepted||validated||isLoading ? true : false}
                    />
          Do not click here. Proceed to submit registration</span>

          {
          <TextField type ='password' required name="payLaterCode"
        variant="outlined"
           error={formErrors.some(error => error.name === "payLaterCode")}
           value={formValues.payLaterCode}
           disabled = {!codeAccepted||!payLater||validated||isLoading ? true : false}
            InputProps={{ className: classes.input }}
            sx={{

    width: { sm: 100, md: 100 }
}}



          onChange={payLaterChange}/>
        }

</Stack>
        <section>
        <div className="description">
        <Paper><b>Total Price is {totalPrice}$</b></Paper>
        </div>
        </section>

          {formErrors.length>0&&<div className="errors">
          <Alert variant="outlined" severity="error">
          {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
          </Alert></div>}

          {successUpload&&!isLoading&&!payLater&&validated&&<div className="proceed">
          <Alert variant="outlined" severity="warning" > <b>Your registration will not be completed unless you pay the total fee.
          Proceed to payment to complete your registration!</b></Alert>
          </div>}

          {successUpload&&!isLoading&&payLater&&registrationId&&validated&&
            <div className="SuccessPage"
            style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
            <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
              <Paper><span className="orangespan">Registration is not complete until you arrange your payment.<br/></span> An email was sent to <b>{formValues.email}</b>.<br />Please check your spam/junk folder also.<br />The details of your registration are:<br/>
              <p><b>Registration ID:</b>{registrationId}</p>
              <p><b>First Name: </b> {formValues.fname}</p>
                <p><b>Last Name: </b>{formValues.lname}</p>
                <p><b>Total Fee: </b>{totalPrice}$</p>
                <p><b>Attendance Type: </b>{formValues.section}</p>
    {
                <p><b>General Categories: </b>{newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
                .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replace(/,/g," ").replace(/\s+/g, ' ')}</p>
              }
              {<p>
                {newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
                .map((sum,test)=>{ if (selectedTests.includes(test.testId))  return test.testName}))}
                        </p>}
                <p><b>Please save this information for future reference of your registration.</b></p>

                </Paper>
            </div>}

          {!isLoading&&!validated&&<div>
           <Button endIcon={!validated&&<UploadIcon/>}
            onClick = {validateSubmit} variant="contained"
            disabled={!((totalPrice>0 /*|| formValues.country==='Ukraine'*/)&& isChecked && !validated)}
            type="button" autoFocus >
             {!validated&&"Submit Registration"}
           </Button>
           </div>}

          {successUpload&&!payLater&&!isLoading&&registrationId&&<div>
            <Button endIcon={validated && <CreditCardIcon/>} onClick = {handlePay} variant="contained"  disabled={isLoading/*isSubmit*/ } type="submit" autoFocus>
              "Pay now" {totalPrice}$
            </Button>
            </div>}

          {(isCountryLoading||isLoading /*&&!isPay*/) &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        </form>
      </div>
  );
}

export default CreateRegistration;
