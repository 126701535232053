import React from "react";


const Navbar = () => {

return (
    <nav className="navbar">
      <h1>  </h1>
         <a  href="https://omchairworld.com/">
          <img src={process.env.PUBLIC_URL + "/banner.png"}  alt="logo" width= "100%" height="80px" />
          <img src={process.env.PUBLIC_URL + "/OMC-logo2.png"}  alt="logo" width="0%" height="0px" />
          </a>

      </nav>
);

}


export default Navbar;
